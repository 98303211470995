module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":false,"displayName":true,"fileName":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"resolvableExtensions":[],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/Users/davidequattrocchi/Desktop/Projects/pags-templates-master/koent-website/src/templates/customer.tsx"},"autoCreateSrcPages":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Koent S.r.l.","short_name":"Koent","start_url":"/","background_color":"#189AB4","theme_color":"#189AB4","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bbdcd708abaeee833eeb3d2b38670dd1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5XEZXTG3Z8","GTM-M4PBXVM"],"gtagConfig":{"optimize_id":"GTM-M4PBXVM","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"9219618","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"enableCcpa":true,"countryDetection":true,"cookiePolicyInOtherWindow":true,"enableTcf":true,"googleAdditionalConsentMode":true,"ccpaAcknowledgeOnDisplay":true,"lang":"it","siteId":2162536,"logLevel":"info","skipSaveConsent":false,"cookiePolicyId":30723280,"privacyPolicyUrl":"/privacy-policy","cookiePolicyUrl":"/cookie-policy","banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"bottom","backgroundOverlay":true,"listPurposes":true}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

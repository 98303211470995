exports.components = {
  "component---src-templates-customer-tsx": () => import("./../../../src/templates/customer.tsx" /* webpackChunkName: "component---src-templates-customer-tsx" */),
  "component---src-templates-customers-index-tsx": () => import("./../../../src/templates/customersIndex.tsx" /* webpackChunkName: "component---src-templates-customers-index-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-partners-index-tsx": () => import("./../../../src/templates/partnersIndex.tsx" /* webpackChunkName: "component---src-templates-partners-index-tsx" */),
  "component---src-templates-policy-tsx": () => import("./../../../src/templates/policy.tsx" /* webpackChunkName: "component---src-templates-policy-tsx" */),
  "component---src-templates-portfolio-index-tsx": () => import("./../../../src/templates/portfolioIndex.tsx" /* webpackChunkName: "component---src-templates-portfolio-index-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */)
}

